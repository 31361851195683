<template>
	<div v-if="organisation" key="organisation" class="page-section">
		<div class="page-section__inner gr gr--wrap">
			<div class="col col--1@tablet hide show@tablet"></div>
			<div class="col col--12 col--10@tablet">
				<div class="page-section__card">
					<div class="page-section__card__details">
						<div>
							<VHeading level="2" classes="page-section__card__title">
								{{ organisation.name }}
							</VHeading>
							<VText size="small" v-html="organisation.overview" />
							<div
								class="subtitle subtitle--big page-section__card__details__subtitle"
							>
								Contact information
							</div>
							<div class="page-section__card__details__info">
								<div class="page-section__card__details__info__inner">
									<div v-if="organisation.email" key="email" class="item">
										<VText size="small" weight="bold" classes="item__label">
											Org email
										</VText>
										<div class="item__value">
											<VLink
												size="small"
												classes="email"
												:href="`mailto:${organisation.email}`"
											>
												{{ organisation.email }}
											</VLink>
										</div>
									</div>
									<div v-if="organisation.website" key="website" class="item">
										<VText size="small" weight="bold" classes="item__label">
											Website
										</VText>
										<div class="item__value">
											<VLink
												size="small"
												target="_blank"
												:href="organisation.website"
											>
												{{ organisation.website }}
											</VLink>
										</div>
									</div>
									<div v-if="organisation.country" key="country" class="item">
										<VText size="small" weight="bold" classes="item__label">
											Location
										</VText>
										<VText size="small" classes="item__label">
											{{ organisation.country.name }}
										</VText>
									</div>
									<div
										v-if="organisation.address"
										key="address"
										class="item item--no-center"
									>
										<VText size="small" weight="bold" classes="item__label">
											Address
										</VText>
										<VText
											size="small"
											classes="item__label"
											v-html="organisation.address"
										/>
									</div>
								</div>

								<div class="page-section__card__details__info__inner">
									<OrganisationSocials :organisation="organisation"/>
								</div>
							</div>
						</div>
						<div class="page-section__card__details__profile">
							<div class="organisation-logo">
								<img :src="organisation.image" alt="" />
							</div>
							<VText
								size="small"
								weight="bold"
								classes="page-section__card__details__profile__title"
								v-if="organisation.specialisms.length"
							>
								Specialisms
							</VText>
							<div class="page-section__list">
								<div
									v-for="(specialism, index) in organisation.specialisms"
									:key="`specialism-${index}`"
									class="page-section__list__item"
								>
									<div>
										<img
											src="@/assets/imgs/icons/icon-arrow-point.svg"
											alt="arrow point"
										/>
									</div>
									<VText size="regular">
										{{ specialism.name }}
									</VText>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col col--1@tablet hide show@tablet"></div>
		</div>
	</div>
</template>

<script>
import { FETCH_ORGANISATION } from '@/store/actions.type';

export default {
	name: 'OrganisationProfilePage',
	data() {
		return {
			organisation: null
		};
	},
	watch: {
		'$route.params.slug': { handler: 'fetchOrganisation', immediate: true }
	},
	methods: {
		async fetchOrganisation(organisation) {
            if (!organisation) return;

			this.organisation = await this.$store.dispatch(
				FETCH_ORGANISATION,
				organisation
			);
		}
	}
};
</script>
